.case-page-header {
  height: 750px;
  width: 100%;
  background-image: url('../../assets/img/case-page/case-header-bg.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 305px;
}
.case-page-header h1 {
  font-size: 3.75rem;
  font-weight: normal;
  color: #ffffff;
  margin: 0;
}
@media (max-width: 960px) {
  .case-page-header {
    height: 250px;
  }
  .case-page-header h1 {
    font-size: 1.25rem;
  }
}
.case-page-container {
  max-width: 1280px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 30px;
  min-height: 770px;
}
.case-page-container:last-child {
  margin-bottom: 0;
}
@media (max-width: 960px) {
  .case-page-container {
    margin-bottom: 206px;
    min-height: 400px;
  }
}
.case-page-container .projects-cases-container-description-line {
  height: 660px;
}
@media (max-width: 960px) {
  .case-page-container .projects-cases-container-description-line {
    height: 223px;
    position: absolute;
  }
}
.case-page-container-show {
  display: flex;
  height: auto;
  align-items: flex-end;
  position: relative;
}
@media (max-width: 960px) {
  .case-page-container-show {
    position: absolute;
    width: 100%;
  }
}
.case-page-container-show img {
  object-fit: contain;
  transition: all 0.5s ease;
}
.case-page-container-show-radpad-imgs img:first-child,
.case-page-container-show-radpad-imgs img:last-child {
  height: 414px;
  width: 205px;
  z-index: 2;
}
.case-page-container-show-radpad-imgs img:nth-child(2) {
  height: 487px;
  width: 240px;
  z-index: 3;
}
.case-page-container-show-radpad-imgs img:first-child {
  transform: translateX(58px);
}
.case-page-container-show-radpad-imgs img:last-child {
  transform: translateX(-58px);
}
@media (max-width: 960px) {
  .case-page-container-show-radpad-imgs {
    justify-content: center;
  }
  .case-page-container-show-radpad-imgs img:first-child {
    transform: translateX(25px);
  }
  .case-page-container-show-radpad-imgs img:last-child {
    transform: translateX(-25px);
  }
  .case-page-container-show-radpad-imgs img:nth-child(2) {
    width: 102px;
    height: 205px;
  }
  .case-page-container-show-radpad-imgs img:first-child,
  .case-page-container-show-radpad-imgs img:last-child {
    height: 174px;
    width: 86px;
  }
}
.case-page-container-show-radpad-imgs:hover img:first-child {
  transform: rotate(-20deg) translateX(38px);
}
.case-page-container-show-radpad-imgs:hover img:last-child {
  transform: rotate(20deg) translateX(-38px);
}
.case-page-container-show-moego-imgs {
  width: 600px;
  padding-top: 40px;
}
.case-page-container-show-moego-imgs img:first-child {
  width: 167px;
  z-index: 3;
}
.case-page-container-show-moego-imgs img:last-child {
  width: 605px;
  z-index: 2;
  transform: translate(-195px, 2px);
}
.case-page-container-show-moego-imgs:hover img:first-child {
  transform: rotate(-19deg) translate(20px, -2px);
}
@media (max-width: 960px) {
  .case-page-container-show-moego-imgs {
    padding-top: 0;
  }
  .case-page-container-show-moego-imgs img:first-child {
    width: 74px;
    transform: translate(0, 36px);
  }
  .case-page-container-show-moego-imgs img:last-child {
    width: 270px;
    transform: translate(-70px, 30px);
  }
}
.case-page-container-show-harmay-imgs img {
  width: 214px;
  height: 436px;
}
.case-page-container-show-harmay-imgs img:first-child {
  z-index: 2;
  transform: translate(46px, 80px);
}
.case-page-container-show-harmay-imgs img:last-child {
  z-index: 3;
}
.case-page-container-show-harmay-imgs:hover img:first-child {
  transform: translate(-40px, 0);
}
@media (max-width: 960px) {
  .case-page-container-show-harmay-imgs {
    justify-content: center;
  }
  .case-page-container-show-harmay-imgs img {
    width: 94px;
    height: 190px;
  }
  .case-page-container-show-harmay-imgs img:first-child {
    transform: translate(0, 26px);
  }
  .case-page-container-show-harmay-imgs img:last-child {
    transform: translate(-26px, 0);
  }
  .case-page-container-show-harmay-imgs:hover img:first-child {
    transform: translate(0, 26px);
  }
  .case-page-container-show-harmay-imgs:hover img:last-child {
    transform: translate(10px, 26px);
  }
}
.case-page-container-show-cheese-imgs {
  justify-content: flex-end;
}
.case-page-container-show-cheese-imgs img {
  width: 207px;
  height: 419px;
}
.case-page-container-show-cheese-imgs img:first-child {
  z-index: 3;
}
.case-page-container-show-cheese-imgs img:last-child {
  z-index: 2;
  transform: translate(-26px, 70px);
}
.case-page-container-show-cheese-imgs:hover img:last-child {
  transform: translate(40px, 0);
}
@media (max-width: 960px) {
  .case-page-container-show-cheese-imgs {
    justify-content: center;
  }
  .case-page-container-show-cheese-imgs img {
    width: 100px;
    height: 202px;
  }
  .case-page-container-show-cheese-imgs img:first-child {
    transform: translate(26px, 0);
  }
  .case-page-container-show-cheese-imgs img:last-child {
    transform: translate(0, 26px);
  }
  .case-page-container-show-cheese-imgs:hover img:first-child {
    transform: translate(-10px, 26px);
  }
  .case-page-container-show-cheese-imgs:hover img:last-child {
    transform: translate(0, 26px);
  }
}
.case-page-container-show-carez-imgs img {
  width: 236px;
  height: 466px;
}
.case-page-container-show-carez-imgs:hover img:last-child {
  transform: rotate(20deg);
}
@media (max-width: 960px) {
  .case-page-container-show-carez-imgs {
    justify-content: center;
  }
  .case-page-container-show-carez-imgs img {
    width: 108px;
    height: 213px;
  }
}
.case-page-container-show-qihuibao-imgs {
  width: 570px;
  height: 367px;
}
.case-page-container-show-qihuibao-imgs img {
  position: absolute;
}
.case-page-container-show-qihuibao-imgs img:first-child {
  width: 100%;
  height: 100%;
  z-index: 2;
  top: 50px;
  left: 0;
}
.case-page-container-show-qihuibao-imgs img:nth-child(2) {
  width: 185px;
  z-index: 3;
  top: -82px;
  right: 41px;
}
.case-page-container-show-qihuibao-imgs img:last-child {
  width: 185px;
  z-index: 3;
  right: 41px;
  bottom: -122px;
}
.case-page-container-show-qihuibao-imgs:hover img:nth-child(2) {
  top: -72px;
  right: 192px;
}
.case-page-container-show-qihuibao-imgs:hover img:last-child {
  right: 192px;
  bottom: -110px;
}
@media (max-width: 960px) {
  .case-page-container-show-qihuibao-imgs {
    width: 278px;
    height: 164px;
    transform: translateY(40px);
  }
  .case-page-container-show-qihuibao-imgs img:first-child {
    top: 0;
  }
  .case-page-container-show-qihuibao-imgs img:nth-child(2) {
    width: 79px;
    top: -60px;
    right: 0;
  }
  .case-page-container-show-qihuibao-imgs img:last-child {
    width: 79px;
    right: 0;
    bottom: -34px;
  }
  .case-page-container-show-qihuibao-imgs:hover img:nth-child(2) {
    top: -60px;
    right: 99px;
  }
  .case-page-container-show-qihuibao-imgs:hover img:last-child {
    right: 99px;
    bottom: -30px;
  }
}
.case-page-container-show-glasses-imgs {
  width: 632px;
  height: 468px;
}
.case-page-container-show-glasses-imgs img {
  position: absolute;
}
.case-page-container-show-glasses-imgs img:first-child {
  width: 632px;
  z-index: 3;
  right: 0;
  bottom: -27px;
}
.case-page-container-show-glasses-imgs img:last-child {
  width: 216px;
  z-index: 2;
  top: 0;
  right: 0;
}
.case-page-container-show-glasses-imgs:hover img:last-child {
  transform: rotate(20deg) translate(18px, 34px);
}
@media (max-width: 960px) {
  .case-page-container-show-glasses-imgs {
    transform: translateX(40px);
    width: 178px;
    height: 215px;
  }
  .case-page-container-show-glasses-imgs img:first-child {
    width: 260px;
    right: 0;
    bottom: 0;
  }
  .case-page-container-show-glasses-imgs img:last-child {
    width: 99px;
    top: 0;
    right: 0;
  }
  .case-page-container-show-glasses-imgs:hover img:last-child {
    transform: rotate(20deg) translate(18px, 34px);
  }
}
.case-page-container-show-kems-imgs img {
  width: 177px;
}
.case-page-container-show-kems-imgs img:nth-child(2) {
  z-index: 3;
}
.case-page-container-show-kems-imgs img:first-child {
  z-index: 2;
  transform: translate(48px, 73px);
}
.case-page-container-show-kems-imgs img:last-child {
  z-index: 2;
  transform: translate(-48px, 73px);
}
.case-page-container-show-kems-imgs:hover img:first-child {
  transform: rotate(-20deg) translate(10px, 38px);
}
.case-page-container-show-kems-imgs:hover img:last-child {
  transform: rotate(20deg) translate(-10px, 38px);
}
@media (max-width: 960px) {
  .case-page-container-show-kems-imgs {
    justify-content: center;
  }
  .case-page-container-show-kems-imgs img {
    width: 98px;
  }
  .case-page-container-show-kems-imgs img:nth-child(2) {
    transform: translate(0, 0);
  }
  .case-page-container-show-kems-imgs img:first-child {
    transform: translate(24px, 24px);
  }
  .case-page-container-show-kems-imgs img:last-child {
    transform: translate(-24px, 24px);
  }
  .case-page-container-show-kems-imgs:hover img:first-child {
    transform: rotate(-20deg) translate(10px, 24px);
  }
  .case-page-container-show-kems-imgs:hover img:last-child {
    transform: rotate(20deg) translate(-10px, 24px);
  }
}
.case-page-container-show-shopshops-imgs img {
  width: 239px;
}
.case-page-container-show-shopshops-imgs img:last-child {
  margin-left: 20px;
}
.case-page-container-show-shopshops-imgs:hover img:first-child {
  transform: rotate(20deg);
}
.case-page-container-show-shopshops-imgs:hover img:last-child {
  transform: rotate(20deg) translate(20px);
}
@media (max-width: 960px) {
  .case-page-container-show-shopshops-imgs {
    justify-content: center;
  }
  .case-page-container-show-shopshops-imgs img {
    width: 105px;
  }
  .case-page-container-show-shopshops-imgs img:last-child {
    margin-left: 10px;
  }
  .case-page-container-show-shopshops-imgs:hover img:first-child {
    transform: rotate(20deg);
  }
  .case-page-container-show-shopshops-imgs:hover img:last-child {
    transform: rotate(20deg) translate(10px);
  }
}
.case-page-container-show-rabbit-imgs img {
  width: 239px;
}
.case-page-container-show-rabbit-imgs img:last-child {
  margin-left: 20px;
}
.case-page-container-show-rabbit-imgs:hover img:first-child {
  transform: rotate(20deg);
}
.case-page-container-show-rabbit-imgs:hover img:last-child {
  transform: rotate(20deg) translate(20px);
}
@media (max-width: 960px) {
  .case-page-container-show-rabbit-imgs {
    justify-content: center;
  }
  .case-page-container-show-rabbit-imgs img {
    width: 105px;
  }
  .case-page-container-show-rabbit-imgs img:last-child {
    margin-left: 10px;
  }
  .case-page-container-show-rabbit-imgs:hover img:first-child {
    transform: rotate(20deg);
  }
  .case-page-container-show-rabbit-imgs:hover img:last-child {
    transform: rotate(20deg) translate(10px);
  }
}
.case-page-container-show-prismPop-imgs {
  flex-grow: 1;
  height: 420px;
}
.case-page-container-show-prismPop-imgs img {
  position: absolute;
}
.case-page-container-show-prismPop-imgs img:first-child {
  width: 100%;
  top: 0;
  left: 0;
}
.case-page-container-show-prismPop-imgs img:last-child {
  width: 110px;
  right: 86px;
  bottom: -34px;
}
.case-page-container-show-prismPop-imgs:hover img:last-child {
  transform: translateY(-36px);
}
@media (max-width: 960px) {
  .case-page-container-show-prismPop-imgs {
    width: 358px;
    height: 226px;
    transform: translate(-30px, 10px);
  }
  .case-page-container-show-prismPop-imgs img:first-child {
    width: 100%;
    top: 0;
    left: 0;
  }
  .case-page-container-show-prismPop-imgs img:last-child {
    width: 63px;
    right: 52px;
    bottom: -14px;
  }
  .case-page-container-show-prismPop-imgs:hover img:last-child {
    transform: translateY(-26px);
  }
}
.case-page-container-show-tripalink-imgs {
  padding-top: 80px;
}
.case-page-container-show-tripalink-imgs img {
  width: 622px;
}
@media (max-width: 960px) {
  .case-page-container-show-tripalink-imgs {
    padding-top: 0;
    transform: translateY(30px);
  }
  .case-page-container-show-tripalink-imgs img {
    width: 293px;
  }
}
.case-page-container-show-trashaus-imgs img {
  width: 191px;
}
.case-page-container-show-trashaus-imgs img:nth-child(2) {
  z-index: 3;
}
.case-page-container-show-trashaus-imgs img:first-child {
  z-index: 2;
  transform: translate(48px, 73px);
}
.case-page-container-show-trashaus-imgs img:last-child {
  z-index: 2;
  transform: translate(-48px, 73px);
}
.case-page-container-show-trashaus-imgs:hover img:first-child {
  transform: rotate(-20deg) translate(5px, 98px);
}
.case-page-container-show-trashaus-imgs:hover img:last-child {
  transform: rotate(20deg) translate(-5px, 98px);
}
@media (max-width: 960px) {
  .case-page-container-show-trashaus-imgs {
    justify-content: center;
  }
  .case-page-container-show-trashaus-imgs img {
    width: 99px;
  }
  .case-page-container-show-trashaus-imgs img:nth-child(2) {
    transform: translate(0, -12px);
  }
  .case-page-container-show-trashaus-imgs img:first-child {
    transform: translate(19px, 10px);
  }
  .case-page-container-show-trashaus-imgs img:last-child {
    transform: translate(-19px, 10px);
  }
  .case-page-container-show-trashaus-imgs:hover img:first-child {
    transform: rotate(-20deg) translate(5px, 10px);
  }
  .case-page-container-show-trashaus-imgs:hover img:last-child {
    transform: rotate(20deg) translate(-5px, 10px);
  }
}
