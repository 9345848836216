.footer-wrap {
  background: #1F222B;
  color: #ffffff;
  padding: 50px 0;
}
@media (max-width: 576px) {
  .footer-wrap-contacts {
    text-align: center;
  }
}
.footer-wrap-contacts-logo {
  height: 50px;
  margin-bottom: 10px;
  padding-left: 45px;
}
@media (max-width: 576px) {
  .footer-wrap-contacts-logo {
    padding-left: 0;
  }
}
.footer-wrap-contacts-logo img {
  height: 50px;
}
.footer-wrap-contacts-item {
  line-height: 1.8rem;
  display: block;
  padding-left: 50px;
}
@media (max-width: 576px) {
  .footer-wrap-contacts-item {
    justify-content: center;
    padding-left: 0px;
  }
}
.footer-wrap-contacts-item img {
  margin-right: 10px;
}
.footer-wrap-contacts-phone {
  display: flex;
  align-items: baseline;
}
.footer-wrap-navs {
  padding-top: 70px;
}
@media (max-width: 576px) {
  .footer-wrap-navs {
    text-align: center;
  }
}
.footer-wrap-navs div {
  text-decoration: underline;
  margin-bottom: 20px;
}
.footer-wrap-navs div:hover {
  cursor: pointer;
}
@media (max-width: 576px) {
  .footer-wrap-addresses {
    margin-top: 20px;
    margin-bottom: 50px;
  }
}
.footer-wrap-addresses-item {
  margin-bottom: 20px;
}
@media (max-width: 576px) {
  .footer-wrap-addresses-item {
    text-align: center;
  }
}
.footer-wrap-addresses-item-logo {
  padding: 0 20px;
}
.footer-wrap-addresses-item-logo img {
  width: 90%;
}
.footer-wrap-addresses-item-city {
  font-size: 1.7rem;
  font-weight: 500;
  letter-spacing: 0.4rem;
}
.footer-wrap-addresses-item-content {
  display: flex;
  line-height: 1.8rem;
}
@media (max-width: 576px) {
  .footer-wrap-addresses-item-content {
    justify-content: center;
  }
}
.footer-wrap-addresses-item-content img {
  height: 1rem;
  position: relative;
  top: 0.4rem;
  margin-right: 10px;
}
.footer-wrap-addresses-item:nth-child(3) {
  margin-bottom: 0;
}
.footer-beian {
  background-color: #000000;
  color: #ffffff;
  padding: 10px 0;
}
.footer-beian-company {
  text-align: right;
}
@media (max-width: 576px) {
  .footer-beian-company {
    text-align: center;
  }
}
@media (max-width: 576px) {
  .footer-beian-icpnumber {
    text-align: center;
  }
}
