.site-header-nav {
  background: #fff;
  padding: 24px 0;
  display: flex;
  justify-content: space-between;
}
.site-header-nav-logo {
  margin-left: 109px;
  height: 50px;
}
.site-header-nav-btn-group {
  margin-right: 99px;
}
.site-header-nav-btn-group-item {
  display: inline-block;
  text-decoration: none;
  text-align: center;
  color: #242424;
  padding: 12px;
  margin-right: 20px;
}
.site-header-nav-btn-group .nav-active {
  color: #6079D2;
}
.site-header-nav-btn-mobile-group {
  display: none;
}
.site-header-mobile-nav {
  background: #fff;
  padding: 8px 5px;
  justify-content: space-between;
  display: none;
  height: 50px;
  overflow: hidden;
  transition: 0.5s all ease-in;
}
.site-header-mobile-nav-header-logo {
  margin-left: 20px;
  height: 35px;
}
.site-header-mobile-nav-header-icon {
  position: absolute;
  right: 20px;
  height: 18px;
  top: 15px;
}
.site-header-mobile-nav-btn-group {
  margin-right: 99px;
}
.site-header-mobile-nav-btn-group-item {
  width: 100vw;
  display: block;
  text-decoration: none;
  text-align: center;
  color: #242424;
  height: 50px;
  line-height: 50px;
}
.site-header-mobile-nav-btn-group .nav-active {
  color: #6079D2;
}
.site-header-mobile-nav.show-nav {
  transition: 0.5s all ease-in;
  height: 200px;
}
@media (max-width: 576px) {
  .site-header-nav {
    display: none;
  }
  .site-header-mobile-nav {
    display: block;
  }
}
