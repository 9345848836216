.core-team .core-team-title {
  margin: 60px 0 80px;
}
.core-team .core-team-title .team-title1 {
  font-size: 16px;
  color: #6079D2;
}
.core-team .core-team-title .team-title2 {
  font-size: 24px;
  color: #36454C;
  margin: 1px 0 15px;
}
.core-team .core-team-title .team-introduction {
  font-weight: 300;
  font-size: 16px;
  line-height: 34px;
  color: #000000;
}
.core-team .core-team-content .team-list {
  display: flex;
  flex-wrap: wrap;
}
.core-team .core-team-content .team-list .team-li {
  width: 25%;
  position: relative;
}
@media (max-width: 576px) {
  .core-team .core-team-content .team-list .team-li {
    width: 100%;
    margin-bottom: 25px;
  }
}
.core-team .core-team-content .team-list .team-li .team-li-pic {
  width: 100%;
  display: block;
}
.core-team .core-team-content .team-list .team-li .intro-box {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
  padding: 60px 16% 40px;
  color: #fff;
  text-align: center;
  font-size: 14px;
  opacity: 0;
  transition: all 0.6s;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.core-team .core-team-content .team-list .team-li .intro-box .intro-title {
  font-weight: 500;
  line-height: 22px;
}
.core-team .core-team-content .team-list .team-li .intro-box .intro-subtitle {
  margin-top: 3px;
}
.core-team .core-team-content .team-list .team-li .intro-box .intro-info {
  margin-top: 24px;
  font-weight: 300;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.05em;
}
.core-team .core-team-content .team-list .team-li:hover .intro-box {
  opacity: 1;
}
.core-team .core-team-content .team-list .team-li .intro-box-mobile {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.05em;
  background-color: rgba(0, 0, 0, 0.75);
  color: #fff;
}
.core-team .core-team-content .team-list .team-li .intro-box-mobile .intro-title {
  margin-right: 3px;
}
.core-team .core-team-content .team-list .team-li .intro-box-mobile .intro-subtitle {
  margin-left: 3px;
}
