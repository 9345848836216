.wapper {
  max-width: 1220px;
  margin-left: auto;
  margin-right: auto;
}
@media (max-width: 960px) {
  .wapper {
    padding-left: 40px;
    padding-right: 40px;
  }
}
.mixin-arrow {
  content: '';
  border: unset;
  margin: 0;
  background: #444;
  width: 14px;
  margin-left: -7px;
  height: 2px;
  position: absolute;
  top: 50%;
  left: 50%;
  border-radius: 6px;
}
.case-detail-header {
  width: 100%;
  position: relative;
}
@media (max-width: 960px) {
}
.case-detail-header img:first-child {
  width: 100%;
}
.case-detail-imgs-radpad img:last-child {
  position: absolute;
  right: 0;
  bottom: -156px;
  height: 596px;
}
@media (max-width: 960px) {
  .case-detail-imgs-radpad img:last-child {
    position: absolute;
    right: 0;
    bottom: -172px;
    height: 216px;
  }
}
.case-detail-imgs-moego img:last-child {
  position: absolute;
  left: 53%;
  bottom: -220px;
  width: 700px;
  margin-left: -410px;
}
@media (max-width: 960px) {
  .case-detail-imgs-moego img:last-child {
    width: 286px;
    margin-left: -143px;
    bottom: -158px;
  }
}
.case-detail-imgs-harmay img:last-child {
  position: absolute;
  right: 124px;
  bottom: -124px;
  width: 424px;
}
@media (max-width: 960px) {
  .case-detail-imgs-harmay img:last-child {
    width: 210px;
    right: 33px;
    bottom: -177px;
  }
}
.case-detail-imgs-cheese img:last-child {
  position: absolute;
  right: 238px;
  bottom: -120px;
  width: 518px;
}
@media (max-width: 960px) {
  .case-detail-imgs-cheese img:last-child {
    width: 204px;
    left: 50%;
    right: unset;
    bottom: -210px;
    margin-left: -102px;
  }
}
.case-detail-imgs-carez img:last-child {
  position: absolute;
  right: 245px;
  bottom: -150px;
  width: 347px;
}
@media (max-width: 960px) {
  .case-detail-imgs-carez img:last-child {
    width: 140px;
    bottom: -206px;
    right: 45px;
  }
}
.case-detail-imgs-qihuibao img:last-child {
  position: absolute;
  left: 52%;
  bottom: -170px;
  width: 730px;
  margin-left: -415px;
}
@media (max-width: 960px) {
  .case-detail-imgs-qihuibao img:last-child {
    width: 290px;
    margin-left: -145px;
    bottom: -158px;
  }
}
.case-detail-imgs-glasses img:last-child {
  position: absolute;
  right: 180px;
  bottom: -224px;
  width: 606px;
}
@media (max-width: 960px) {
  .case-detail-imgs-glasses img:last-child {
    width: 300px;
    bottom: -220px;
    right: 58px;
  }
}
.case-detail-imgs-swype img:last-child {
  position: absolute;
  right: 194px;
  bottom: -106px;
  width: 756px;
}
@media (max-width: 960px) {
  .case-detail-imgs-swype img:last-child {
    width: 280px;
    margin-left: -140px;
    bottom: -200px;
    right: unset;
    left: 50%;
  }
}
.case-detail-imgs-wapipay img:last-child {
  position: absolute;
  right: 194px;
  bottom: -106px;
  width: 756px;
}
@media (max-width: 960px) {
  .case-detail-imgs-wapipay img:last-child {
    width: 280px;
    margin-left: -140px;
    bottom: -200px;
    right: unset;
    left: 50%;
  }
}
.case-detail-imgs-kems img:last-child {
  position: absolute;
  right: 94px;
  bottom: -146px;
  width: 656px;
}
@media (max-width: 960px) {
  .case-detail-imgs-kems img:last-child {
    width: 280px;
    margin-left: -140px;
    bottom: -200px;
    right: unset;
    left: 50%;
  }
}
.case-detail-imgs-shopshops img:last-child {
  position: absolute;
  right: 151px;
  bottom: -135px;
  width: 576px;
}
@media (max-width: 960px) {
  .case-detail-imgs-shopshops img:last-child {
    width: 238px;
    margin-left: -119px;
    bottom: -195px;
    right: unset;
    left: 50%;
  }
}
.case-detail-imgs-prismPop img:last-child {
  position: absolute;
  right: 134px;
  bottom: -132px;
  width: 800px;
}
@media (max-width: 960px) {
  .case-detail-imgs-prismPop img:last-child {
    width: 340px;
    margin-left: -170px;
    bottom: -146px;
    right: unset;
    left: 50%;
  }
}
.case-detail-imgs-tripalink img:last-child {
  position: absolute;
  left: 52%;
  bottom: -150px;
  width: 780px;
  margin-left: -440px;
}
@media (max-width: 960px) {
  .case-detail-imgs-tripalink img:last-child {
    width: 310px;
    margin-left: -155px;
    bottom: -150px;
  }
}
.case-detail-imgs-trashaus img:last-child {
  position: absolute;
  right: 135px;
  bottom: -145px;
  width: 680px;
}
@media (max-width: 960px) {
  .case-detail-imgs-trashaus img:last-child {
    width: 310px;
    margin-left: -155px;
    bottom: -190px;
    right: unset;
    left: 50%;
  }
}
.case-detail-imgs-rabbit img:last-child {
  position: absolute;
  right: 75px;
  bottom: -145px;
  width: 780px;
}
@media (max-width: 960px) {
  .case-detail-imgs-rabbit img:last-child {
    width: 310px;
    margin-left: -155px;
    bottom: -190px;
    right: unset;
    left: 50%;
  }
}
.case-detail-technology {
  margin: 200px auto 88px auto;
  max-width: 1220px;
  margin-left: auto;
  margin-right: auto;
}
@media (max-width: 960px) {
  .case-detail-technology {
    padding-left: 40px;
    padding-right: 40px;
  }
}
.case-detail-technology-title {
  display: flex;
  align-items: center;
}
.case-detail-technology-title > span:first-child img {
  width: 24px;
  height: 21px;
}
.case-detail-technology-title > span:first-child img + img {
  margin-left: 17px;
}
.case-detail-technology-title > span:last-child {
  font-weight: bold;
  font-size: 1.25rem;
  margin-left: 39px;
}
.case-detail-technology-tags {
  margin-top: 36px;
}
.case-detail-technology-tags-title {
  color: #8A8889;
  font-size: 1rem;
}
.case-detail-technology-tags-wrap {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.case-detail-technology-tags-wrap > span {
  display: flex;
  align-items: center;
  background: #F5F5F5;
  border-radius: 20px;
  padding: 6.5px 18px;
  margin-right: 18px;
  margin-top: 16px;
}
.case-detail-technology-tags-wrap > span > img:first-child {
  height: 23px;
}
.case-detail-technology-tags-wrap > span > span:last-child {
  margin-left: 15px;
  font-size: 0.875rem;
  color: #818181;
}
@media (max-width: 960px) {
  .case-detail-technology {
    margin: 223px auto 49px auto;
  }
  .case-detail-technology-tags {
    margin-top: 20px;
  }
  .case-detail-technology-tags-title {
    font-size: 0.875rem;
  }
  .case-detail-technology-tags-wrap > span {
    padding: 4px 12px;
  }
  .case-detail-technology-tags-wrap > span > img:first-child {
    height: 16px;
  }
}
.case-detail-project {
  max-width: 1220px;
  margin-left: auto;
  margin-right: auto;
  background: #ffffff;
  padding-top: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media (max-width: 960px) {
  .case-detail-project {
    padding-left: 40px;
    padding-right: 40px;
  }
}
.case-detail-project-wrap {
  max-width: 1220px;
  margin-left: auto;
  margin-right: auto;
}
@media (max-width: 960px) {
  .case-detail-project-wrap {
    padding-left: 40px;
    padding-right: 40px;
  }
}
.case-detail-project-wrap-title {
  font-size: 1.875rem;
  font-weight: bold;
}
.case-detail-project-wrap-content {
  color: #666666;
  font-size: 18px;
  margin-top: 22px;
  line-height: 34px;
  letter-spacing: 1px;
}
.case-detail-project-wrap-tags {
  margin-top: 36px;
}
.case-detail-project-wrap-tags-title {
  color: #000000;
  margin-top: 32px;
  font-weight: 400;
  font-size: 1rem;
}
.case-detail-project-wrap-tags-wrap {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.case-detail-project-wrap-tags-wrap > span {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 20px;
  padding-right: 30px;
  margin-top: 16px;
}
.case-detail-project-wrap-tags-wrap > span > img:first-child {
  height: 23px;
}
.case-detail-project-wrap-tags-wrap > span > span:last-child {
  margin-left: 15px;
  font-size: 0.875rem;
  color: #818181;
}
@media (max-width: 960px) {
  .case-detail-project-wrap {
    margin: 223px auto 49px auto;
  }
  .case-detail-project-wrap-tags {
    margin-top: 20px;
  }
  .case-detail-project-wrap-tags-title {
    font-size: 0.875rem;
  }
  .case-detail-project-wrap-tags-wrap > span {
    padding: 4px 12px;
  }
  .case-detail-project-wrap-tags-wrap > span > img:first-child {
    height: 16px;
  }
}
@media (max-width: 960px) {
  .case-detail-project {
    padding-bottom: 50px;
  }
  .case-detail-project-wrap-title {
    font-size: 1.25rem;
  }
  .case-detail-project-wrap-content {
    padding-right: 0;
    margin-top: 24px;
    font-size: 1rem;
  }
}
.case-detail-demand {
  max-width: 1220px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 200px;
}
@media (max-width: 960px) {
  .case-detail-demand {
    padding-left: 40px;
    padding-right: 40px;
  }
}
.case-detail-demand-content {
  width: 500px;
  flex-shrink: 0;
  font-size: 1rem;
  margin-top: -40px;
  padding-right: 20px;
}
.case-detail-demand-content-title {
  font-size: 1.875rem;
  line-height: 3.75rem;
  font-weight: bold;
  margin-top: 17px;
}
.case-detail-demand-content-title-wapipay {
  font-size: 1.725rem;
}
.case-detail-demand-content-detail {
  font-size: 1.125rem;
  color: #737373;
  line-height: 2.5rem;
  margin-top: 26px;
  letter-spacing: 1px;
}
.case-detail-demand > img:last-child {
  width: 567px;
  height: 567px;
  object-fit: contain;
  flex-shrink: 0;
}
@media (max-width: 1140px) {
  .case-detail-demand > img:last-child {
    width: 400px;
    height: 400px;
  }
}
@media (max-width: 960px) {
  .case-detail-demand > img:last-child {
    width: 280px;
    height: 280px;
    margin-top: 30px;
  }
}
@media (max-width: 960px) {
  .case-detail-demand {
    flex-direction: column;
    margin-top: 111px;
  }
  .case-detail-demand-content {
    margin-top: 0;
    font-size: 0.875rem;
    width: auto;
    padding-right: 0;
  }
  .case-detail-demand-content-title {
    font-size: 1.25rem;
    line-height: 2.125rem;
    margin-top: 8px;
  }
  .case-detail-demand-content-detail {
    font-size: 0.875rem;
    line-height: 1.625rem;
    margin-top: 23px;
  }
}
@media (max-width: 960px) {
  .case-detail-solution {
    margin-top: 59px;
  }
  .case-detail-solution .case-detail-demand-content {
    order: unset !important;
  }
}
.case-detail-challenges {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #F9FAFC;
  padding: 40px 0;
  margin-top: 192px;
  max-width: 1220px;
  margin-left: auto;
  margin-right: auto;
}
@media (max-width: 960px) {
  .case-detail-challenges {
    padding-left: 40px;
    padding-right: 40px;
  }
}
.case-detail-challenges-title > div:first-child {
  font-size: 1rem;
}
.case-detail-challenges-title > div:last-child {
  font-size: 1.875rem;
  font-weight: bold;
  margin-top: 17px;
}
.case-detail-challenges-list {
  width: 784px;
  background: #242424;
  padding: 62px 38px 22px 92px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}
.case-detail-challenges-list-item {
  position: relative;
  margin-bottom: 40px;
}
.case-detail-challenges-list-item > div:first-child {
  color: #ffffff;
  font-size: 1.25rem;
}
.case-detail-challenges-list-item > div:nth-child(2) {
  color: #fff;
  font-size: 1.25rem;
  line-height: 2.5rem;
  margin-top: 19px;
}
.case-detail-challenges-list-item > img:last-child {
  position: absolute;
  width: 30px;
  height: 30px;
  left: -47px;
  top: 0;
}
@media (max-width: 960px) {
  .case-detail-challenges {
    margin-top: 59px;
    padding-top: 52px;
    padding-bottom: 52px;
    flex-direction: column;
    align-items: flex-start;
  }
  .case-detail-challenges-title {
    padding-left: 12px;
  }
  .case-detail-challenges-title > div:first-child {
    font-size: 0.875rem;
  }
  .case-detail-challenges-title > div:last-child {
    font-size: 1.25rem;
    margin-top: 8px;
  }
  .case-detail-challenges-list {
    margin-top: 48px;
    padding: 26px 33px 35px 63px;
    width: auto;
  }
  .case-detail-challenges-list-item > div:first-child {
    font-size: 1rem;
  }
  .case-detail-challenges-list-item > div:nth-child(2) {
    font-size: 0.875rem;
    line-height: 1.625rem;
    margin-top: 10px;
    letter-spacing: 1px;
  }
  .case-detail-challenges-list-item > img:last-child {
    width: 16px;
    height: 16px;
    left: -28px;
    top: 3px;
  }
}
.case-detail-achievement {
  background: #F9FAFC;
  padding: 95px 0;
  min-height: 483px;
  margin-top: 225px;
}
.case-detail-achievement-wrap {
  max-width: 1220px;
  margin-left: auto;
  margin-right: auto;
}
@media (max-width: 960px) {
  .case-detail-achievement-wrap {
    padding-left: 40px;
    padding-right: 40px;
  }
}
.case-detail-achievement-wrap-title {
  font-size: 1.875rem;
  color: #000000;
  font-weight: bold;
}
.case-detail-achievement-wrap-content {
  color: #444444;
  font-size: 22px;
  line-height: 40px;
  margin-top: 41px;
  max-width: 970px;
  letter-spacing: 1px;
}
@media (max-width: 960px) {
  .case-detail-achievement {
    margin-top: 46px;
    padding-top: 52px;
    padding-bottom: 52px;
    min-height: unset;
  }
  .case-detail-achievement-wrap {
    max-width: 1220px;
    margin-left: auto;
    margin-right: auto;
  }
  .case-detail-achievement-wrap-title {
    font-size: 1.25rem;
  }
  .case-detail-achievement-wrap-content {
    font-size: 1rem;
    line-height: 1.75rem;
    margin-top: 24px;
    max-width: unset;
  }
}
@media (max-width: 960px) and (max-width: 960px) {
  .case-detail-achievement-wrap {
    padding-left: 40px;
    padding-right: 40px;
  }
}
.case-detail-carousel {
  background: #1F222B;
  margin-top: 35px;
  padding: 84px 0 100px 0;
}
.case-detail-carousel-title {
  font-size: 2.5rem;
  color: #fff;
  text-align: center;
}
.case-detail-carousel-item {
  max-width: 519px;
  height: calc(850px - 184px - 62px);
  display: flex;
  align-items: flex-end;
  padding-bottom: 70px;
  justify-content: center;
}
.case-detail-carousel-item > img {
  max-height: 510px;
  width: 150% !important;
}
.case-detail-carousel-item-moego {
  padding-bottom: 100px;
}
.case-detail-carousel-item-moego > img {
  width: 120% !important;
}
.case-detail-carousel-item-prismPop {
  max-width: 918px;
}
@media (max-width: 960px) {
  .case-detail-carousel {
    padding: 44px 0 58px 0;
  }
  .case-detail-carousel-title {
    font-size: 1.25rem;
  }
  .case-detail-carousel-item {
    max-width: calc(100vw - 130px);
    height: calc(480px - 102px - 62px);
  }
  .case-detail-carousel-item > img {
    max-height: 220px;
    width: 100% !important;
  }
  .case-detail-carousel-item-moego {
    padding-bottom: 70px;
  }
}
.case-detail-carousel .carousel .slider-wrapper .slide {
  display: flex;
  justify-content: center;
  flex-direction: row !important;
  flex-flow: row !important;
  opacity: 0.3;
}
.case-detail-carousel .carousel .slider-wrapper .slide .case-detail-carousel-item {
  cursor: pointer;
}
.case-detail-carousel .carousel .slider-wrapper .selected {
  opacity: 1;
}
.case-detail-carousel .carousel .control-dots {
  display: flex;
  align-items: center;
  justify-content: center;
}
.case-detail-carousel .carousel .control-dots .dot {
  margin: 0 4px;
  width: 12px;
  height: 12px;
  background: #C4C4C4;
}
.case-detail-carousel .carousel .control-dots .selected {
  width: 18px;
  height: 8px;
  background: #5578F1;
  border-radius: 4px;
}
.case-detail-carousel .carousel.carousel-slider .control-arrow {
  bottom: unset;
  top: 50%;
  left: 44px;
  margin-top: -35px;
  padding: 0;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  background-color: #FFFFFF;
  opacity: 0.8;
}
.case-detail-carousel .carousel.carousel-slider .control-arrow:hover {
  opacity: 1;
  background-color: #fff;
}
.case-detail-carousel .carousel.carousel-slider .control-arrow::before {
  content: '';
  border: unset;
  margin: 0;
  background: #444;
  width: 14px;
  margin-left: -7px;
  height: 2px;
  position: absolute;
  top: 50%;
  left: 50%;
  border-radius: 6px;
  margin-top: 3px;
  transform: rotate(40deg);
}
.case-detail-carousel .carousel.carousel-slider .control-arrow::after {
  content: '';
  border: unset;
  margin: 0;
  background: #444;
  width: 14px;
  margin-left: -7px;
  height: 2px;
  position: absolute;
  top: 50%;
  left: 50%;
  border-radius: 6px;
  margin-top: -5px;
  transform: rotate(-40deg);
}
@media (max-width: 960px) {
  .case-detail-carousel .carousel.carousel-slider .control-arrow {
    width: 34px;
    height: 34px;
    margin-top: -17px;
    left: 16px;
  }
  .case-detail-carousel .carousel.carousel-slider .control-arrow::before {
    width: 7px;
    height: 1px;
    margin-top: 1px;
    margin-left: -4px;
  }
  .case-detail-carousel .carousel.carousel-slider .control-arrow::after {
    width: 7px;
    height: 1px;
    margin-top: -3px;
    margin-left: -4px;
  }
}
.case-detail-carousel .carousel.carousel-slider .control-next {
  left: unset;
  right: 44px;
}
.case-detail-carousel .carousel.carousel-slider .control-next::before {
  content: '';
  border: unset;
  margin: 0;
  background: #444;
  width: 14px;
  margin-left: -7px;
  height: 2px;
  position: absolute;
  top: 50%;
  left: 50%;
  border-radius: 6px;
  margin-top: 3px;
  transform: rotate(-40deg);
}
.case-detail-carousel .carousel.carousel-slider .control-next::after {
  content: '';
  border: unset;
  margin: 0;
  background: #444;
  width: 14px;
  margin-left: -7px;
  height: 2px;
  position: absolute;
  top: 50%;
  left: 50%;
  border-radius: 6px;
  margin-top: -5px;
  transform: rotate(40deg);
}
@media (max-width: 960px) {
  .case-detail-carousel .carousel.carousel-slider .control-next {
    right: 16px;
  }
  .case-detail-carousel .carousel.carousel-slider .control-next::before {
    width: 7px;
    height: 1px;
    margin-left: -3px;
    margin-top: 1px;
  }
  .case-detail-carousel .carousel.carousel-slider .control-next::after {
    width: 7px;
    height: 1px;
    margin-left: -3px;
    margin-top: -3px;
  }
}
.case-detail-empty {
  margin: 100px auto;
  text-align: center;
}
