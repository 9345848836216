.button-arrow {
  background: #6079D2;
  height: 34px;
  line-height: 34px;
  padding: 0 29px;
  font-size: 1rem;
  border-radius: 18px;
  text-align: center;
  color: #ffffff;
  cursor: pointer;
  display: inline-block;
}
.button-arrow-line {
  position: relative;
  margin-left: 10px;
}
.button-arrow-line span:first-child {
  position: absolute;
  width: 10px;
  height: 1px;
  background-color: #fff;
  left: 3px;
  top: 50%;
  opacity: 0;
  transition: all 0.2s;
}
.button-arrow-line span:last-child {
  position: absolute;
  transition: all 0.15s linear;
  left: -2px;
  top: 50%;
  transform: translateY(-50%);
  font-weight: 700;
  font-size: 14px;
  margin-top: 2px;
  z-index: 2;
}
.button-arrow .arrow-icon {
  margin-left: 12px;
}
.button-arrow:hover {
  opacity: 0.6;
}
.button-arrow:hover > span span:first-child {
  opacity: 1;
}
.button-arrow:hover > span span:last-child {
  left: 3px;
}
.button-lower-arrow .button-arrow-line span:first-child {
  transform: translateY(-50%) rotate(90deg);
}
.button-lower-arrow .button-arrow-line span:last-child {
  left: 0;
  top: calc(50% - 1.5px);
  transform: translateY(-50%) rotate(90deg);
}
.button-lower-arrow:hover > span span:last-child {
  left: 0;
  top: calc(50% + 2px);
}
.button-arrow.button-arrow-mobile .button-arrow-line span:first-child {
  opacity: 1;
}
.button-arrow.button-arrow-mobile .button-arrow-line span:last-child {
  left: 3px;
}
.button-arrow.button-arrow-mobile:hover {
  opacity: 1;
}
.button-arrow.button-arrow-mobile:hover > span span:last-child {
  left: 3px;
}
.button-lower-arrow.button-arrow-mobile .button-arrow-line span:first-child {
  transform: translateY(-50%) rotate(90deg);
}
.button-lower-arrow.button-arrow-mobile .button-arrow-line span:last-child {
  left: 0;
  top: calc(50% + 2px);
  transform: translateY(-50%) rotate(90deg);
}
.button-lower-arrow.button-arrow-mobile:hover > span span:last-child {
  left: 0;
  top: calc(50% + 2px);
}
