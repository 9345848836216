.footer-page-concat {
  text-align: center;
  margin-top: 137px;
  color: #0F2137;
}
.footer-page-concat-title {
  font-size: 2.5rem;
  font-weight: bold;
}
.footer-page-concat-content {
  font-size: 1rem;
  margin: 38px auto;
  padding: 0 30px;
  max-width: 600px;
}
@media (max-width: 960px) {
  .footer-page-concat {
    margin-top: 100px;
  }
  .footer-page-concat-title {
    font-size: 1.5rem;
    font-weight: bold;
  }
  .footer-page-concat-content {
    margin: 30px auto;
  }
}
.footer-page-footer-line {
  width: 50%;
  height: 30px;
  margin-top: 135px;
  position: relative;
}
@media (max-width: 960px) {
  .footer-page-footer-line {
    margin-top: 45px;
  }
  .footer-page-footer-line::before {
    display: none;
  }
  .footer-page-footer-line::after {
    display: none;
  }
}
