.technology-stack {
  transition: all 0.1s;
  overflow: hidden;
}
.technology-stack .pack-up {
  margin-top: 30px;
  text-align: center;
}
.technology-stack .pack-up .anticon {
  font-size: 20px;
  cursor: pointer;
}
.technology-stack .technology-stack-title .stack-title1 {
  font-size: 16px;
  color: #8A8889;
}
.technology-stack .technology-stack-title .stack-title2 {
  font-size: 24px;
  color: #36454C;
  margin-top: 6px;
}
.technology-stack .technology-stack-content {
  width: 488px;
  margin: 100px auto;
  overflow: hidden;
}
@media (max-width: 576px) {
  .technology-stack .technology-stack-content {
    width: 100% !important;
    margin: 60px auto;
  }
}
.technology-stack .technology-tabs {
  display: flex;
  justify-content: center;
  align-items: center;
}
.technology-stack .technology-tabs .tab-li {
  font-family: PingFang HK;
  cursor: pointer;
  font-size: 26px;
  line-height: 44px;
  text-align: center;
  letter-spacing: 0.05em;
  padding: 0 26px;
  color: #C4C4C4;
}
.technology-stack .technology-tabs .ac-tab-li {
  color: #000000;
}
.technology-stack .technology-warp {
  animation: leftMove 1s;
}
.technology-stack .technology-warp .technology-li {
  margin-top: 25px;
}
.technology-stack .technology-warp .technology-li .technology-li-title {
  text-align: center;
  margin-bottom: 15px;
  color: #000000;
  font-weight: 600;
}
.technology-stack .technology-warp .technology-li .stack-list {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.technology-stack .technology-warp .technology-li .stack-list .stack-li {
  flex: 1;
}
@media (max-width: 576px) {
  .technology-stack .technology-warp .technology-li .stack-list .stack-li {
    flex: auto;
    width: 50%;
  }
}
.technology-stack .technology-warp .technology-li .stack-list .stack-li .stack-li-icon {
  margin: 0 auto;
  display: flex;
  justify-content: center;
  width: 84px;
  height: 84px;
  margin-bottom: 5px;
  align-items: center;
  border-radius: 50%;
  transition: all 0.3s;
}
.technology-stack .technology-warp .technology-li .stack-list .stack-li .stack-li-icon img {
  width: 30px;
  height: 30px;
}
.technology-stack .technology-warp .technology-li .stack-list .stack-li .stack-li-icon .stack-li-icon-active {
  display: none;
}
.technology-stack .technology-warp .technology-li .stack-list .stack-li .stack-li-icon:hover {
  background: #FFFFFF;
  box-shadow: 2px 6px 10px rgba(216, 216, 216, 0.8);
  color: #36454C;
}
.technology-stack .technology-warp .technology-li .stack-list .stack-li .stack-li-icon:hover .stack-li-icon-inactive {
  display: none;
}
.technology-stack .technology-warp .technology-li .stack-list .stack-li .stack-li-icon:hover .stack-li-icon-active {
  display: block;
}
.technology-stack .technology-warp .technology-li .stack-list .stack-li .stack-li-label {
  font-family: PingFang HK;
  font-weight: 300;
  font-size: 14px;
  line-height: 44px;
  text-align: center;
  letter-spacing: 0.05em;
  color: #8A8889;
}
.technology-stack .technology-warp1 {
  animation: leftMove1 0.5s;
}
@keyframes leftMove {
  0% {
    transform: translateX(400px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes leftMove1 {
  0% {
    transform: translateX(180px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}
