.success-fulCases {
  text-align: left;
}
.success-fulCases .case-list {
  display: flex;
  flex-wrap: wrap;
}
.success-fulCases .case-li {
  margin-right: 30px;
  width: calc((100% - 60px) / 3);
  transition: 0.5s all;
  position: relative;
  border-radius: 10px;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  height: 532px;
}
@media (max-width: 576px) {
  .success-fulCases .case-li {
    height: 24.25rem;
  }
}
.success-fulCases .case-li .case-li-content {
  padding: 60px 37px 23px 48px;
}
@media (max-width: 576px) {
  .success-fulCases .case-li .case-li-content {
    padding: 30px 33px 15px 33px;
  }
}
.success-fulCases .case-li .case-logo-warp {
  position: absolute;
  border-radius: 0px 0px 0px 10px;
  right: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #FFFFFF;
  box-shadow: 0px 4px 4px rgba(216, 216, 216, 0.25);
  width: 96px;
}
.success-fulCases .case-li .picWarp {
  display: flex;
  justify-content: center;
  margin: 40px 0;
  position: relative;
}
.success-fulCases .case-li .picWarp .picWarp-pic {
  position: relative;
  z-index: 1;
  transition: 0.5s all;
}
.success-fulCases .case-li .picWarp .picWarp-pic1 {
  width: 283px;
}
.success-fulCases .case-li .picWarp .picWarp-pic2 {
  width: 198px;
}
.success-fulCases .case-li .picWarp .picWarp-pic3 {
  width: 186px;
}
.success-fulCases .case-li .picWarp .picWarp-pie {
  position: absolute;
  width: 100%;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  height: 160px;
  border-radius: 50%;
  background-color: red;
  transition: 0.5s all;
}
.success-fulCases .case-li .picWarp2 {
  margin: 8px 0 34px;
}
.success-fulCases .case-li .picWarp3 {
  margin: 18px 0 20px;
}
.success-fulCases .case-li .case-li-title,
.success-fulCases .case-li .case-li-txt {
  opacity: 0;
  transition: 0.5s all;
  color: #000000;
}
.success-fulCases .case-li .case-country {
  width: 19px;
  margin-right: 12px;
  margin-top: -3px;
}
.success-fulCases .case-li .case-li-title {
  transform: translateY(100px);
  line-height: 22px;
}
.success-fulCases .case-li .case-li-title .case-sub-title {
  font-weight: 500;
  font-size: 30px;
  letter-spacing: 0.05em;
}
.success-fulCases .case-li .case-li-title .case-intro {
  font-size: 16px;
  letter-spacing: 0.05em;
  margin-top: 18px;
}
.success-fulCases .case-li .case-li-txt {
  transform: translateY(-110px);
  font-family: PingFang SC;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 30px;
  letter-spacing: 0.05em;
}
.success-fulCases .case-li:hover {
  height: 532px;
}
@media (max-width: 576px) {
  .success-fulCases .case-li:hover {
    height: 24.25rem;
  }
}
@media (max-width: 576px) {
  .success-fulCases .case-li:hover .case-li-content {
    padding: 50px 33px 15px 33px;
  }
}
.success-fulCases .case-li:hover .picWarp-pic1 {
  width: 256px;
}
@media (max-width: 576px) {
  .success-fulCases .case-li:hover .picWarp-pic1 {
    width: 11.25rem;
  }
}
.success-fulCases .case-li:hover .picWarp-pic2 {
  width: 171px;
}
@media (max-width: 576px) {
  .success-fulCases .case-li:hover .picWarp-pic2 {
    width: 6.75rem;
  }
}
.success-fulCases .case-li:hover .picWarp-pic3 {
  width: 158px;
}
@media (max-width: 576px) {
  .success-fulCases .case-li:hover .picWarp-pic3 {
    width: 6.75rem;
  }
}
.success-fulCases .case-li:hover .picWarp-pie {
  width: 0;
  opacity: 1;
  left: 40%;
  height: 0;
}
.success-fulCases .case-li:hover .case-li-title,
.success-fulCases .case-li:hover .case-li-txt {
  opacity: 1;
}
.success-fulCases .case-li:hover .case-li-title {
  transform: translateY(0);
}
.success-fulCases .case-li:hover .case-li-txt {
  transform: translateY(0);
}
.success-fulCases .case-li:last-child {
  margin-right: 0;
}
@media (max-width: 576px) {
  .success-fulCases .case-li {
    width: 100%;
    margin-right: 0;
    height: 24.25rem;
    margin-bottom: 50px;
  }
  .success-fulCases .case-li .case-li-content {
    padding: 2.125rem 2.0625rem 0.9375rem 2.0625rem;
  }
  .success-fulCases .case-li .case-logo-warp {
    width: 80px;
  }
  .success-fulCases .case-li .picWarp {
    display: flex;
    justify-content: center;
    margin: 20px 0;
    position: relative;
  }
  .success-fulCases .case-li .picWarp .picWarp-pic1 {
    width: 11.25rem;
  }
  .success-fulCases .case-li .picWarp .picWarp-pic2 {
    width: 6.75rem;
  }
  .success-fulCases .case-li .picWarp .picWarp-pic3 {
    width: 6.75rem;
  }
  .success-fulCases .case-li .picWarp .picWarp-pie {
    position: absolute;
    width: 100%;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    height: 160px;
    border-radius: 50%;
    background-color: red;
    transition: 0.5s all;
  }
  .success-fulCases .case-li .picWarp2 {
    margin: 8px 0 34px;
  }
  .success-fulCases .case-li .picWarp3 {
    margin: 18px 0 20px;
  }
  .success-fulCases .case-li .case-li-title,
  .success-fulCases .case-li .case-li-txt {
    color: #000000;
    opacity: 1;
  }
  .success-fulCases .case-li .case-country {
    width: 13px;
    margin-right: 8px;
    margin-top: -3px;
  }
  .success-fulCases .case-li .case-li-title {
    transform: translateY(0);
    line-height: 22px;
  }
  .success-fulCases .case-li .case-li-title .case-sub-title {
    font-size: 22px;
  }
  .success-fulCases .case-li .case-li-title .case-intro {
    font-size: 12px;
    margin-top: 14px;
  }
  .success-fulCases .case-li .case-li-txt {
    transform: translateY(0);
    font-size: 12px;
    line-height: 26px;
  }
}
.success-fulCases .case-li2 .case-li-title,
.success-fulCases .case-li2 .case-li-txt {
  color: #fff;
}
.success-fulCases .ant-col-xs-6 {
  display: flex;
  justify-content: center;
}
